import React, { Component } from "react";
import { tsParticles } from "tsparticles-engine";
import equal from "deep-eql";
const defaultId = "tsparticles";
class Particles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            init: false,
            library: undefined,
        };
    }
    destroy() {
        if (!this.state.library) {
            return;
        }
        this.state.library.destroy();
        this.setState({
            library: undefined,
        });
    }
    shouldComponentUpdate(nextProps) {
        console.dir({ props: this.props, nextProps });
        return !equal(nextProps, this.props);
    }
    componentDidUpdate() {
        this.refresh();
    }
    forceUpdate() {
        this.refresh().then(() => {
            super.forceUpdate();
        });
    }
    componentDidMount() {
        (async () => {
            if (this.props.init) {
                await this.props.init(tsParticles);
            }
            this.setState({
                init: true,
            }, async () => {
                await this.loadParticles();
            });
        })();
    }
    componentWillUnmount() {
        this.destroy();
    }
    render() {
        const { width, height, className, canvasClassName, id } = this.props;
        return (React.createElement("div", { className: className, id: id },
            React.createElement("canvas", { className: canvasClassName, style: {
                    ...this.props.style,
                    width,
                    height,
                } })));
    }
    async refresh() {
        this.destroy();
        await this.loadParticles();
    }
    async loadParticles() {
        if (!this.state.init) {
            return;
        }
        const cb = async (container) => {
            if (this.props.container) {
                this.props.container.current = container;
            }
            this.setState({
                library: container,
            });
            if (this.props.loaded) {
                await this.props.loaded(container);
            }
        };
        const id = this.props.id ?? Particles.defaultProps.id ?? defaultId, container = this.props.url
            ? await tsParticles.loadJSON(id, this.props.url)
            : await tsParticles.load(id, this.props.options ?? this.props.params);
        await cb(container);
    }
}
Particles.defaultProps = {
    width: "100%",
    height: "100%",
    options: {},
    style: {},
    url: undefined,
    id: defaultId,
};
export default Particles;
